import React, { useEffect, useState } from "react";

const ApiKeyPage = () => {
  const [visibleApiKey, setVisibleApiKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [copied, setCopied] = useState(false);

  const generateApiKey = async () => {
    if (loading) return;

    setLoading(true);

    console.log("cookie", document.cookie);
    const response = await fetch("/api/api-key/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        credentials: "include",
        // pull token from cookie
        "X-CSRFToken": window.document.cookie.split("=")[1], //  for local testing
        // "X-CSRFToken": window.csrfToken,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setVisibleApiKey(data.api_key);
    }

    setLoading(false);
  };

  useEffect(() => {
    const loadApiKeys = async () => {
      const response = await fetch("/api/api-keys/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          credentials: "include",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setApiKeys(data.api_keys);
      }
    };

    loadApiKeys();
  }, []);

  return (
    <div className="min-h-screen bg-richblack text-lavender flex flex-col items-center p-6">
      {/* Page Header */}
      <header className="w-full max-w-4xl text-center py-6">
        <h1 className="text-3xl font-bold mb-4">API Keys</h1>
        <p className="text-grey">Generate a personal API key below.</p>
      </header>

      {/* API Key Section */}
      <section className="w-full max-w-4xl bg-grey p-6 rounded-md mb-8">
        {apiKeys.length > 0 && (
          <div className="p-2 mt-4 ">
            <h2 className="text-xl font-bold mb-4">Your API Keys</h2>
            <ul>
              {apiKeys.map((keyObj) => {
                const date = new Date(keyObj.created_at);

                const formattedDate = new Intl.DateTimeFormat("en-US", {
                  month: "short", // Full month name
                  day: "numeric", // Day of the month
                  year: "numeric", // Include year
                  hour: "numeric", // Hour
                  minute: "numeric", // Minute
                  hour12: true, // 12-hour format
                }).format(date);

                return (
                  <li key={keyObj.id} className="text-sm">
                    <div className="flex flex-row">
                      <div>{formattedDate}</div>
                      <div className="ml-4 text-lavender font-mono">
                        {keyObj.created_by}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="flex items-center justify-between bg-richblack p-4 rounded-md">
          <span className="text-aquamarine font-mono">{visibleApiKey}</span>
          {!visibleApiKey && (
            <button
              disabled={loading}
              className="border border-pumpkin text-white px-4 py-2 rounded-md hover:bg-richblack cursor-pointer"
              onClick={() => generateApiKey()}
            >
              Generate New Key
            </button>
          )}
          {visibleApiKey && (
            <button
              className="ml-2 border border-pumpkin text-nowrap text-white px-4 py-2 rounded-md hover:bg-richblack "
              onClick={() => {
                if (!visibleApiKey) return;
                navigator.clipboard.writeText(visibleApiKey);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}
            >
              Copy Key{copied ? " ✔" : ""}
            </button>
          )}
        </div>

        <p className="text-sm text-grey mt-2">
          Keep your API keys secure. Do not share any publicly or expose them in
          client-side code.
        </p>
      </section>

      {/* Authentication Guide Section */}
      <section className="w-full max-w-4xl bg-richblack p-6 rounded-md">
        <h2 className="text-xl font-bold mb-4">Test Your Setup</h2>

        <p className="my-4">
          Use the{" "}
          <code className="text-aquamarine font-mono">Authorization</code>{" "}
          header with the value{" "}
          <code className="text-aquamarine font-mono">
            Bearer &lt;API_KEY&gt;
          </code>
          .
        </p>
        <p className="mb-4">
          Authenticate API requests by including your API key in the headers.
          Paste this cURL command in your terminal to verify your setup. Make
          sure to replace{" "}
          <code className="text-aquamarine font-mono">your-api-key-here</code>{" "}
          with your actual API key.
        </p>

        <pre className="bg-grey text-lavender p-4 rounded-md overflow-auto">
          <code>
            {`curl -X GET "https://postkit.dev/api/setup/" \\
-H "Authorization: Bearer your-api-key-here" \\`}
          </code>
        </pre>
      </section>
    </div>
  );
};

export default ApiKeyPage;
