import React from "react";
// import { useEffect, useState } from "react";
import LogViewer from "./LogViewer";

const Dashboard = () => {
  // const [user, setUser] = useState(null);
  // useEffect(() => {
  //   fetch("/api/auth-status/", {
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       if (res.status === 401) {
  //         window.location.href = "/login";
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //       setUser(data);
  //     });
  // }, []);

  // useEffect(() => {
  //   fetch("/api/logging-history/", {
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       if (res.status === 401) {
  //         window.location.href = "/login";
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     });
  // }, []);

  return (
    <>
      {/* <h2 className="text-2xl font-bold text-lavender">Logging History</h2> */}
      <LogViewer />
    </>
  );
};

export default Dashboard;
