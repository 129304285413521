import React from "react";
import { useEffect, useState } from "react";

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    fetch("/api/auth-status/", {
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          window.location.href = "/";
        }
        return res.json();
      })
      .then((data) => {
        if (!data.email) {
          window.location.href = "/";
        }

        setUser(data);
      });
  }, []);

  const activeUrl = window.location.pathname;
  const menuItems = [
    {
      title: "Logging",
      url: "/dashboard/",
      active: activeUrl.includes("/dashboard/"),
    },
    {
      title: "API Keys",
      url: "/api_key/",
      active: activeUrl.includes("/api_key/"),
    },
    // {
    //   title: "Profile",
    //   url: "/profile/",
    //   active: activeUrl.includes("/profile/"),
    // },
    // {
    //   title: "Settings",
    //   url: "/settings/",
    //   active: activeUrl.includes("/settings/"),
    // },
  ];

  return (
    <div className="min-h-screen bg-richblack text-lavender flex flex-col">
      {/* Header */}
      <header className="bg-darkgreen text-white px-4 py-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-aquamarine font-mono">
          postkit
        </h1>
        <div className="flex flex-row gap-4">
          <p className="text-aquamarine text-xs">{user?.email}</p>
          <p
            onClick={() =>
              fetch("/api/logout/", {
                credentials: "include",
              }).then(() => {
                window.location = "/";
              })
            }
            className="text-aquamarine text-xs cursor-pointer"
          >
            logout
          </p>
        </div>
        {/* <button className="sm:hidden text-white bg-aquamarine px-3 py-2 rounded-md">
          Menu
        </button> */}
      </header>

      <div className="flex flex-1">
        {/* Sidebar */}
        <aside className="hidden sm:flex flex-col bg-darkgreen w-56 p-4 text-aquamarine">
          <nav>
            <ul className="space-y-4">
              {menuItems.map((item) => (
                <li key={item.url}>
                  <a
                    href={item.url}
                    className={`block px-3 py-2 rounded-sm border  hover:border-lavender ${
                      item.active ? "border-aquamarine" : "border-darkgreen"
                    } `}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        {/* Content */}
        <main className="flex-1 p-6 bg-richblack text-lavender">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
