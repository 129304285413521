import "./Landing.css";

import React from "react";

const LandingPage = () => {
  return (
    <div className="bg-richblack text-lavender min-h-screen">
      {/* Navigation */}
      <nav className="container mx-auto p-6 flex justify-between items-center flex-wrap">
        <div className="text-aquamarine text-2xl font-bold  my-2 sm:my-0">
          postkit
        </div>
        <div className=" items-center flex flex-wrap sm:block">
          <a
            href="#pricing"
            className="text-lavender my-2 sm:my-0  hover:text-aquamarine mx-4 whitespace-nowrap"
          >
            Pricing
          </a>
          <a
            href="#features"
            className="text-lavender my-2 sm:my-0  hover:text-aquamarine mx-4 whitespace-nowrap"
          >
            Features
          </a>
          <a
            href="#faq"
            className="text-lavender my-2 sm:my-0  hover:text-aquamarine mx-4 whitespace-nowrap"
          >
            FAQ
          </a>
          <a
            href="/accounts/signup/"
            className="bg-pumpkin text-richblack px-4 py-2 rounded hover:bg-aquamarine whitespace-nowrap"
          >
            Get Started
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="container mx-auto px-6 py-16 flex flex-col-reverse lg:flex-row items-center">
        {/* Left Content */}
        <div className="w-full lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl font-bold text-aquamarine leading-tight">
            Launch sooner with out of the box APIs for logging, feedback, and
            more
          </h1>
          <p className="mt-6 text-lg">
            Accelerate your project with the easiest API tools on the planet.
            Get up and running in minutes.
          </p>
          <a
            href="/accounts/signup/"
            className="mt-8 inline-block bg-pumpkin text-richblack px-6 py-3 rounded text-lg font-semibold hover:bg-aquamarine"
          >
            Get Started for Free
          </a>
        </div>

        {/* Right Content - Code Example */}
        <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
          <pre className="bg-darkgreen text-lavender p-6 rounded-lg overflow-x-auto">
            <code className="text-sm">
              {`// Simple logging
fetch('https://postkit.dev/log', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'API-Key': 'YOUR_API_KEY'
  },
  body: JSON.stringify({
    level: 'info',
    message: 'User visited the dashboard page'
  })
});`}
            </code>
          </pre>
          <p className="mt-4 text-sm text-center">
            <a
              href="/accounts/signup/"
              className="text-aquamarine hover:underline"
            >
              Sign up
            </a>{" "}
            to get your API key and start integrating immediately.
          </p>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="bg-darkgreen py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-aquamarine">
            Why Choose postkit?
          </h2>
          <div className="mt-12 flex flex-col lg:flex-row justify-around">
            {/* Benefit 1 */}
            <div className="mb-8 lg:mb-0">
              <h3 className="text-xl font-semibold text-pumpkin">
                Ship Sooner
              </h3>
              <p className="mt-4 max-w-64 mx-auto">
                Your app needs these offerings, your timeline doesn't.
              </p>
            </div>
            {/* Benefit 2 */}
            <div className="mb-8 lg:mb-0">
              <h3 className="text-xl font-semibold text-pumpkin">
                Avoid Server Costs
              </h3>
              <p className="mt-4 max-w-64 mx-auto">
                Avoid the expenses of building and paying for servers.
              </p>
            </div>
            {/* Benefit 3 */}
            <div className="mb-8 lg:mb-0">
              <h3 className="text-xl font-semibold text-pumpkin">
                Build Your App
              </h3>
              <p className="mt-4 max-w-64 mx-auto">
                Spend time on what makes your project unique, not on APIs you
                could have for free.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-aquamarine text-center">
            {/* Your App, Our API */}
            Headless solutions to get your project off the ground faster:
          </h2>
          <p className="mt-6 text-lg text-center"></p>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-darkgreen p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-pumpkin">
                Simple Logging API
              </h3>
              <p className="mt-4">
                Implement logging via API without managing servers.
                <a href="/docs/" className="text-aquamarine hover:underline">
                  {" "}
                  Quick Start ↗
                </a>
              </p>
            </div>
            <div className="bg-darkgreen opacity-70 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-pumpkin">
                (coming soon) Feedback Collection API
              </h3>
              <p className="mt-4">
                Effortlessly gather user feedback from in your app.
              </p>
            </div>
            <div className="bg-darkgreen opacity-70 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-pumpkin">
                (coming soon) Remote Config API
              </h3>
              <p className="mt-4">
                Change configuration in postkit, and load it on the fly in your
                app.
              </p>
            </div>
            <div className="bg-darkgreen opacity-70 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-pumpkin">
                (coming soon) Feature Flags API
              </h3>
              <p className="mt-4">Flip features on and off without a deploy.</p>
            </div>
            <div className="bg-darkgreen opacity-70 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-pumpkin">
                (coming soon) Email Collection API
              </h3>
              <p className="mt-4">Collect and export email lists.</p>
            </div>
            <div className="bg-darkgreen p-6 rounded-lg flex items-center justify-center">
              <p className="text-center">
                Missing something, or waiting on a feature?
                <br />
                <a
                  href="mailto:postkitdev@gmail.com"
                  className="text-aquamarine hover:underline"
                >
                  Let us know
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="bg-darkgreen py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-aquamarine text-center">
            Sensible pricing, even for small projects
          </h2>
          <div className="mt-12 flex flex-col md:flex-row justify-center items-center">
            {/* Starter Plan */}
            <div className="bg-richblack text-lavender p-8 rounded-lg m-4 w-full md:w-1/3">
              <h3 className="text-2xl font-bold text-pumpkin text-center">
                Starter Plan
              </h3>
              <p className="mt-4 text-center text-4xl font-bold">
                $0<span className="text-lg">/month</span>
              </p>
              <ul className="mt-6 space-y-4">
                <li>Core API Access</li>
                <li>Log Retention: 7 Days</li>
                <li>Standard Rate Limits</li>
                <li>1 Project Supported</li>
              </ul>
              <a
                href="/accounts/signup/"
                className="mt-8 block bg-pumpkin text-richblack px-4 py-2 rounded text-center font-semibold hover:bg-aquamarine"
              >
                Get Started
              </a>
            </div>

            {/* Growth Plan */}
            <div className="bg-richblack text-lavender p-8 rounded-lg m-4 w-full md:w-1/3">
              <h3 className="text-2xl font-bold text-pumpkin text-center">
                [Coming Soon] Growth Plan
              </h3>
              <p className="mt-4 text-center text-4xl font-bold">
                tbd<span className="text-lg">/month</span>
              </p>
              <ul className="mt-6 space-y-4">
                <li>All API Access</li>
                <li>Log Retention: 90 Days</li>
                <li>Higher Rate Limits</li>
                <li>6 Projects Supported</li>
              </ul>
              <a
                href="/accounts/signup/"
                className="mt-8 block bg-pumpkin text-richblack px-4 py-2 rounded text-center font-semibold hover:bg-aquamarine"
              >
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-aquamarine text-center">
            Frequently Asked Questions
          </h2>
          <div className="mt-12 space-y-8">
            {/* FAQ 1 */}
            <div>
              <h3 className="text-xl font-semibold text-pumpkin">
                Is the Starter Plan really free?
              </h3>
              <p className="mt-2">
                Absolutely! No credit card required, and you can use it for as
                long as you like.
              </p>
            </div>
            {/* FAQ 2 */}
            <div>
              <h3 className="text-xl font-semibold text-pumpkin">
                Can I upgrade or downgrade at any time?
              </h3>
              <p className="mt-2">
                Yes, you have the flexibility to change your plan as your needs
                evolve.
              </p>
            </div>
            {/* FAQ 3 */}
            {/* <div>
              <h3 className="text-xl font-semibold text-pumpkin">
                Do you offer customer support?
              </h3>
              <p className="mt-2">
                Starter users have access to community support, while Growth
                users enjoy priority email support.
              </p>
            </div> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-darkgreen py-8">
        <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
          <div className="text-lavender mb-4 md:mb-0">
            © {new Date().getFullYear()} postkit. All rights reserved.
          </div>
          <div className="flex space-x-6">
            {/* <a href="/about" className="text-lavender hover:text-aquamarine">
              About Us
            </a>
            <a href="/docs" className="text-lavender hover:text-aquamarine">
              Documentation
            </a>
            <a href="/blog" className="text-lavender hover:text-aquamarine">
              Blog
            </a>
            <a href="/terms" className="text-lavender hover:text-aquamarine">
              Terms of Service
            </a>
            <a href="/privacy" className="text-lavender hover:text-aquamarine">
              Privacy Policy
            </a> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
