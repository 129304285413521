// LogViewer.jsx
import React, { useEffect, useState, useRef } from "react";

function LogViewer({ projectId }) {
  const [logs, setLogs] = useState([]);
  const [startId, setStartId] = useState("%2B"); // Start from the most recent log (%2B is +)
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreLogs, setHasMoreLogs] = useState(true);
  const observer = useRef();

  // Function to fetch logs
  const fetchLogs = async () => {
    if (isLoading || !hasMoreLogs || !startId) return;
    setIsLoading(true);

    // count = int(request.GET.get("count", 10))
    // start = request.GET.get("start", "-")
    try {
      const response = await fetch(`/api/logs/?start=${startId}&count=20`);
      const data = await response.json();

      if (data.status === "success") {
        const fetchedLogs = data.logs;
        if (fetchedLogs.length > 0) {
          setLogs((prevLogs) => [...prevLogs, ...fetchedLogs]);

          // Update startId to the ID of the last fetched log minus one
          const lastLogId = fetchedLogs[fetchedLogs.length - 1].id;
          setStartId(lastLogId);

          // If fewer logs than requested were returned, we've reached the end
          if (fetchedLogs.length < 10) {
            setHasMoreLogs(false);
          }
        } else {
          setHasMoreLogs(false);
        }
      } else {
        console.error("Error fetching logs:", data.message);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
    }

    setIsLoading(false);
  };

  // Use Intersection Observer for infinite scrolling
  const lastLogElementRef = (node) => {
    if (isLoading) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreLogs) {
          fetchLogs();
        }
      },
      { threshold: 1.0 }
    );
    if (node) observer.current.observe(node);
  };

  // Initial fetch
  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-richblack text-lavender min-h-screen p-4">
      <h1 className="text-aquamarine text-2xl mb-4">Project Logs</h1>
      <p className="font-bold">Tier limits</p>
      <ul className="mb-4">
        <li>• 7 day TTL</li>
        <li>• 1024 character max</li>
        <li>• 5 requests/s</li>
      </ul>
      <p className="mb-2">
        <a
          href="/docs/"
          target="_blank"
          className="text-pumpkin hover:underline"
        >
          Quick start guide
        </a>
        .
      </p>

      <div className="space-y-2">
        {logs.map((log, index) => {
          if (logs.length === index + 1) {
            // Last log element
            return (
              <div
                ref={lastLogElementRef}
                key={log.id}
                className="bg-darkgreen p-3 rounded"
              >
                <p>{log.message}</p>
                <p className="text-sm text-gray-400">ID: {log.id}</p>
              </div>
            );
          } else {
            // Other log elements
            return (
              <div key={log.id} className="bg-darkgreen p-3 rounded">
                <p>{log.message}</p>
                <p className="text-sm text-gray-400">ID: {log.id}</p>
              </div>
            );
          }
        })}
        {isLoading && <p className="text-center">Loading...</p>}
        {!hasMoreLogs && (
          <p className="text-center text-gray-500">No more logs</p>
        )}
      </div>
    </div>
  );
}

export default LogViewer;
