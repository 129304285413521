import React from "react";

const DocsLandingPage = () => {
  return (
    <div className="bg-richblack text-lavender min-h-screen">
      {/* Header */}
      <header className="bg-darkgreen p-6">
        <h1 className="text-4xl font-bold text-aquamarine">
          Postkit Documentation
        </h1>
      </header>

      {/* Main Content */}
      <main className="p-8">
        {/* Navigation */}
        <nav className="mb-12">
          <ul className="flex space-x-6">
            <li>
              <a href="/" className="text-pumpkin hover:underline">
                Home
              </a>
            </li>
            {/* Add more navigation links here */}
          </ul>
        </nav>

        {/* Guide on Logging */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-aquamarine">
            Getting Started with Logging
          </h2>
          <p className="text-gray-400 mb-6">
            To begin logging,{" "}
            <a href="/accounts/signup/" className="text-pumpkin">
              sign up
            </a>{" "}
            or{" "}
            <a href="/accounts/login/" className="text-pumpkin">
              log in
            </a>{" "}
            and grab an API key from{" "}
            <a href="/api_key/" className="text-pumpkin hover:underline">
              API Keys
            </a>{" "}
            and then use it in a POST request to{" "}
            <span className="text-aquamarine">https://postkit.dev/v1/log/</span>{" "}
            like so:
          </p>
          <pre className="bg-darkgreen p-6 rounded text-sm mb-12 overflow-x-auto">
            {`fetch("https://postkit.dev/v1/log/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer YOUR_API_KEY",
    },
    body: JSON.stringify({
      message: "My first log!",
    }),
  });`}
          </pre>
          <p>
            Then, visit the{" "}
            <a href="/dashboard/" className="text-aquamarine">
              dashboard
            </a>{" "}
            to see your logs!
          </p>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-darkgreen p-6 text-center">
        <p className="text-sm text-gray-500">
          &copy; {new Date().getFullYear()} Postkit. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default DocsLandingPage;
